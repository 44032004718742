export const MAIN_API = process.env.REACT_APP_MAIN_API_URL

export const CLIENT_NAME = ''
export const CLIENT_TOKEN = process.env.REACT_APP_CLIENT_TOKEN

export const MODAL_MSGES = {
  NO_DATA_FOUND: 'No Data Found!! This might be because there is no data available at the moment',
}

export const COLORS = {
  MAIN: '#08679F',
  MID_LIGHT: '#D2CFDF',
  LIGHT: '#FFF4EB',
  DANGER_BTN: '#C40202',
}
